import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	header: {
		textAlign: 'center',
		color: theme.workwaveBlue,
		textAlign: 'center',
		fontWeight: 'bold',
		marginBottom: '4rem',
		marginTop: '4rem',
	},
	image: {
		transition: 'all .5s ease-in-out',
		'&:hover': {
			transform: 'scale(1.05)',
		},
	},
}));

export const PaymentsSpecialSection = ({ header, logos }) => {
	const classes = useStyles();
	return (
		<Grid
			container
			justify='center'
			alignItems='center'
			direction='row'
			spacing={2}>
			<Grid item xs={12}>
				<Typography variant='h3' className={classes.header}>
					{header}
				</Typography>
			</Grid>
			{logos.map((logo, index) => (
				<Grid
					container
					direction='column'
					justifyContent='center'
					alignItems='center'
					item
					md={3}
					xs={6}
					key={index}>
					<a href={logo.externalLink} target='_blank' rel='noopener noreferrer'>
						<GatsbyImage
							image={logo?.logo?.asset?.gatsbyImageData}
							alt='logo'
							className={classes.image}
						/>
					</a>
				</Grid>
			))}
		</Grid>
	);
};
